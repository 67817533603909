/* Estrutura */
.auditors {
    width: 100%;
    display: flex;
    font-family: 'Roboto', sans-serif;
}

.backgroundDark {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #262626;
    z-index: -1;
}

.backgroundLight {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #F2F2F2;
    z-index: -1;
}

.contentPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all ease 0.2s;
}

.contentPageOpen {
    margin-left: 300px;
}

.contentPageClose {
    margin-left: 60px;
}

.contentPageDark {
    background-color: #262626;
}

.contentPageLight {
    background-color: #F2F2F2;
}

/* Estrutura do conteúdo */
.auditorsBox {
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .contentPageOpen {
        margin-left: 60px;
        width: 100%;
    }
    
    .auditorsBox {
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .formAuditors {
        width: 80%;
    }

    .tableAuditors {
        width: 80%;
    }

}