@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  margin: 0;
  border: 0;
  padding: 0;
}

.login {
	display: flex;
	flex-direction: column;
	height: 100vh;
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #FAFBFC;
}

.logoAlest {
	height: 60px;
	width: 100vw;
}

.logoAlest img {
	width: 80px;
	margin: 10px;
}

.logoEdiscovery {
	height: 100px;
	width: 65%;
	margin: 20px 17.5%;
	border-bottom: 1px solid #CCCCCC;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logoEdiscovery img {
	width: 250px;
}

.loginField {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 50px;
}

.loginField span {
	font-size: 24px;
	font-weight: bold;
	color: #3F484F;
	margin-bottom: 10px;
}

.loginField input {
	width: 400px;
	height: 30px;
	border-radius: 10px;
	border: 1px solid #CCCCCC;
	margin: 10px 0;
	text-indent: 10px;
	background-color: #F0F2F5;
	color: #3F484F;
}

.loginField input:focus {
	border: 1px solid #044BD9;
	text-decoration: none;
	outline: 1px solid #044BD9;
}

.loginField button {
	width: 405px;
	height: 35px;
	border-radius: 10px;
	border: none;
	background-color: #044BD9;
	color: #FAFBFC;
	margin: 10px 0;
	font-size: 15px;
	cursor: pointer;
	transition: all ease 0.2s;
}

.loginField button:hover {
	background-color: #034EAA;
	border: none;
	outline: none;
}

.LoginField button :active {
	background-color: #00458D;
}

.productDescription {
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 30px;
}

.productDescription span {
	width: 450px;
	font-style: italic;
	color: #7D838C;
}

.boxPassword {
	position: relative;
	display: flex;
	align-items: center;
}

.passwordShow {
	position: absolute;
	right: 10px;
	cursor: pointer;
}

@media only screen and (max-width: 750px) {
	.logoAlest img {
		width: 60px;
		margin: 10px;
		transition: all ease 0.2s;
	}

	.logoEdiscovery img {
		width: 200px;
		transition: all ease 0.2s;
	}

	.loginField span {
		font-size: 20px;
		transition: all ease 0.2s;
	}

	.loginField input {
		width: 300px;
		transition: all ease 0.2s;
	}

	.loginField button {
		width: 305px;
		transition: all ease 0.2s;
	}

	.productDescription {
		font-size: 25px;
		transition: all ease 0.2s;
	}
}
.loaderComponentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  position: fixed;
  width: calc(100vw - 62px);
  height: 100vh;
  opacity: 70%;
}

.loaderThemeDark {
  background-color: #262626;
}

.loaderThemeLight {
  background-color: #F2F2F2;
}

.lds-dual-ring-dark {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring-dark:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #F2F2F2;
  border-color: #F2F2F2 transparent #F2F2F2 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #262626;
  border-color: #262626 transparent #262626 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.welcome {
  font-size: 48px;
  font-weight: bold;
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.backgroundDark {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  z-index: -1;
}
.backgroundLight {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #F2F2F2;
  z-index: -1;
}

.initialPageContent {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  text-align: center;
  margin: auto;
}
.initialPageDark {
  background-color: #262626;
  color: #FAFBFC;
}
.initialPageLight {
  color: #2C3135;
}
.welcomeText {
  font-size: 24px;
}
.linkGeral {
  color: #0073EA;
  text-decoration: none;
}

@media only screen and (max-width: 750px) {
  .contentPageOpen {
      margin-left: 60px;
  }   
}
.mainMenu {
    position: fixed;
    height: calc(100vh - 16px);
    display: flex;
    flex-direction: column;
    transition: all ease 0.2s;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    padding: 7px 0;
    z-index: 999999999;
}
.mainMenuOpen {
    width: 300px;
}
.mainMenuClose {
    width: 60px;
}
.mainMenuDark {
    background-color: #2C3135; 
    border: 1px solid #3F484F;
    color: #FAFBFC
}
.mainMenuLight {
    background-color: #F6F7F8; 
    border: 1px solid #CCCCCC;
    color: #2C3135;
}

/* Ícone de abrir e fechar o menu */
.toggleMenu {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
    z-index: 9999999;
}
.toggleMenuOpen {
    top: 37.5px;
    left: 293.5px;
}
.toggleMenuClose {
    top: 37.5px;
    left: 53.5px;
}
.toggleMenuDark {
    background-color: #FAFBFC;
    border: 1px solid #3F484F;
}
.toggleMenuLight {
    background-color: #2C3135;
    border: 1px solid #CCCCCC;
}
.arrowMenuOpen {
    width: 7px;
    height: 18px;
    margin: 0.6px 0 0 -3px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.arrowMenuClose {
    width: 7px;
    height: 18px;
    margin-left: 3px;
}

/* Opções do Menu */
.link {
    text-decoration: none;
}
.optionMenu {
    padding-top: 10px;
}
.option {
    height: 45px;
    margin: 7.5px;
    cursor: pointer;
    font-weight: normal;
}
.optionDark {
    color: #FAFBFC;
}
.optionLight {
    color: #2C3135;
}
.optionOpen {
    width: 275px;
    display: flex;
    align-items: center;
}
.optionClose {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
}
.option span {
    margin-left: 15px;
}

.optionDark:hover {
    background-color: #45525B;
    border-radius: 10px;
    cursor: pointer;
}
.optionLight:hover {
    background-color: #DDDEDF;
    border-radius: 10px;
    cursor: pointer;
}
.optionHighlight {
    background-color: #83A1EA !important;
    color: #0073EA !important;
    border-radius: 10px;
    transition: all ease 0.2s;
}
.iconMenu {
    width: 20px;
    height: 20px;
    transition: all ease 0.2s;
}
.iconMenuOpen {
    margin-left: 20px;
}

/* Acesso ao menu de usuário */
.userInfo {
    transition: all ease 0.2s;
    cursor: pointer;
}
.userInfo span {
    margin-left: 15px;
    font-weight: normal;
}
.userInfoDark {
    color: #FAFBFC;
}
.userInfoLight {
    color: #2C3135;
}
.userInfoClose {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 20px;
}
.userInfoOpen {
    display: flex;
    align-items: center;
    margin: auto 0 20px 20px;
}
.userInfo img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    cursor: pointer;
}
.userMenu {
    position: absolute;
    bottom: 5px;
    width: 250px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    transition: all ease 0.2s;
}
.userMenuOpen {
    left: 305px;
    transition: all ease 0.2s;
}
.userMenuClose {
    left: 65px;
    transition: all ease 0.2s;
}
.userMenuDark {
    border: 1px solid #3F484F;
    background-color: #2C3135;
    color: #FAFBFC;
}
.userMenuLight {
    border: 1px solid #CCCCCC;
    background-color: #F6F7F8;
    color: #2C3135;
}
.userOptionDark {
    display: flex;
    width: 240px;
    height: 40px;
    border-radius: 10px;
    margin: 7px 5px;
    align-items: center;
    cursor: pointer;
}
.userOptionLight {
    display: flex;
    width: 240px;
    height: 40px;
    border-radius: 10px;
    margin: 7px 5px;
    align-items: center;
    cursor: pointer;
}
.userOptionDark:hover {
    background-color: #45525B;
}
.userOptionLight:hover {
    background-color: #DDDEDF;
}
.userOptionDark span, .userOptionLight span {
    margin: 13px 12.5px 12.5px 0;
}
.iconUser {
    width: 20px;
    height: 20px;
    margin: 10px;
}

.toggleTheme {
    margin-left: auto;
    margin-right: 10px;
}
.toggle {
    position: relative;
    width: 50px;
    height: 20px;
    border-radius: 10px;
}
.toggleDark {
    background-color: #044BD9;
    border: 1px solid #044BD9;
    transition: all ease 0.2s;
}
.toggleLight {
    background-color: #FAFBFC;
    border: 1px solid #CCCCCC;
    transition: all ease 0.2s;
}
.circleToggle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 1px;
    transition: all ease 0.2s;
}
.circleToggleOn {
    position: absolute;
    left: 30px;
}
.circleToggleOff {
    position: absolute;
    left: 0;
}
.circleToggleDark {
    background-color: #FAFBFC;
    transition: all ease 0.2s;
}
.circleToggleLight {
    background-color: #818386;
    transition: all ease 0.2s;
}
.title {
  min-height: 90px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-family: 'Roboto', sans-serif;
}
.titleOpen {
  margin-left: 300px;
}
.titleClose {
  margin-left: 60px;
}
.titleDark {
  background-color: #262626;
  color: #FAFBFC;
  border-bottom: 1px solid #3F484F;
  transition: all ease 0.2s;
}
.titleLight {
  background-color: #F2F2F2;
  color: #2C3135;
  border-bottom: 1px solid #CCCCCC;
  transition: all ease 0.2s;
}
.productTitle {
  font-size: 30px;
  font-weight: bold;
}
.pageTitle {
  font-size: 25px;
  margin: 5px 0 0 5px;
}

@media only screen and (max-width: 750px) {
  .title {
    width: 100%;
  }   
}
/* Estrutura*/
.dashboard {
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.backgroundDark {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  z-index: -1;
}
.backgroundLight {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #F2F2F2;
  z-index: -1;
}

.contentPage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: all ease 0.2s;
}
.contentPageOpen {
  margin-left: 300px;
}
.contentPageClose {
  margin-left: 60px;
}
.contentPageDark {
  background-color: #262626;
}
.contentPageLight {
  background-color: #F2F2F2;
}

/* Botão CSV */
.btnCSV {
  width: 250px;
  height: 35px;
  font-size: 15px;
  border-radius: 10px;
  margin: 2%;
  padding: 0px;
  background-color: #044BD9;
  color: #FAFBFC;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnCSV span {
  margin-left: 10px;
}
.iconDownload {
  width: 15px;
  margin-right: 10px;
}

/* Conteúdo dashboard */
.analysisDataBox {
  display: flex;
  margin-top: 5%;
}

/* Dados de análise */
.analysisData {
  width: 30%;
  margin: 0 2.5%;
}
.titleDataBox {
  font-weight: bold;
  width: 100%;
  border-bottom: 1px solid #044BD9;
  height: 45px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.titleDataBox span {
  margin-left: 15px;
}
.dataBox {
  display: flex;
  display: flex;
  align-items: center;
  height: 45px;
}
.textDark {
  color: #FAFBFC;
}
.textLight {
  color: #2C3135;
}
.dataName {
  margin-left: 15px;
}
.dataValue {
  margin-left: auto;
  margin-right: 15px;
}

/* Gráfico */
.chartDashboard {
  width: 60%;
  height: 300px;
  margin: 0 2.5%;
}

@media only screen and (max-width: 750px) {

  .backgroundDark, .backgroundLight {
    height: 100%;
  }

  .contentPageOpen {
      margin-left: 60px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
  }
  
    .analysisDataBox {
      display: flex;
      flex-direction: column;
      justify-content: center; 
      align-items: center; 
    }

  .analysisData, .chartDashboard {
    width: 90%;
    padding: 20px 0;
  }
}
.selectCompany {
    position: relative;
    width: 251px;
    height: 35px;
    font-family: 'Roboto', sans-serif;
    margin: 2% 2% 0;
    cursor: pointer;
    transition: all ease 0.2s;
}
.selectOpen {
    border-radius: 10px 10px 0 0;
}
.selectClose {
    border-radius: 10px;
}

/* Cabeçalho */
.selectHeader {
    width: 251px;
    height: 35px;
    display: flex;
    align-items: center;
}
.selectHeader span, .selectOption div span {
    margin-left: 10px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selectHeaderDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
}
.selectHeaderLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
}
.selectHeaderDark span {
    color: #FAFBFC;
}
.selectHeaderLight span {
    color: #2C3135;
}
.selectArrow {
    margin-left: auto;
    margin-right: 10px;
}
.selectArrowOpen {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/* Opções */
.selectOption {
    position: absolute;
    width: 100%;
    border-radius: 0 0 10px 10px;
    top: 30px;
}
.selectOptionDark {
    background-color: #45525B;
}
.selectOptionLight {
    background-color: #F0F2F5;
}
.selectOptionHidden {
    display: none;
}
.selectOption div {
    width: calc(100% - 7px);
    padding-left: 7px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-top: 1px solid #3F484F;
}
.selectOptionDark div {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}
.selectOptionLight div {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3130;
}
.selectOptionDark div:hover {
    background-color: #2C3130;
}
.selectOptionLight div:hover {
    background-color: #CCCCCC;
}
.selectOption div:first-child {
    border-top: 1px solid #044BD9;
}
.selectOption div:last-child {
    border-radius: 0 0 10px 10px;
}
.chartBox {
    width: 100%;
    height: 300px;
}
/* Estrutura */
.activity {
    width: 100%;
    display: flex;
    font-family: 'Roboto', sans-serif;
}

.backgroundDark {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #262626;
    z-index: -1;
}
.backgroundLight {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #F2F2F2;
    z-index: -1;
}

.contentPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all ease 0.2s;
}
.contentPageOpen {
    margin-left: 300px;
}
.contentPageClose {
    margin-left: 60px;
}
.contentPageDark {
    background-color: #262626;
}
.contentPageLight {
    background-color: #F2F2F2;
}

/* Abas e download do CSV */
.companyBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tabActivity {
    display: flex;
    width: 96%;
    height: 35px;
    margin: 2%;
}
.tabActivityDark {
    border-bottom: 1px solid #45525B;
}
.tabActivityLight {
    border-bottom: 1px solid #CCCCCC;
}
.tabBox {
    display: flex;
    width: 50%;
}
.tabBox div:first-child {
    border-radius: 10px 0 0 0;
}
.tabBox div:last-child {
    border-radius: 0 10px 0 0;
}
.tabOption {
    width: 25%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tabOptionDark {
    background-color: #45525B;
    color: #FAFBFC;
}
.tabOptionLight {
    background-color: #F0F2F5;
    color: #2A2D30;
}
.tabOptionDark:hover {
    background-color: #3F484F;
}
.tabOptionLight:hover {
    background-color: #CCCCCC;
}
.tabBtn {
    width: 50%;
}
.btnCsv {
    width: 15%;
    height: 30px;
    margin-left: auto;
    margin: 2% 2% 0;
    border-radius: 10px;
    border: 1px solid #044BD9;
    background-color: #044BD9;
    color: #FAFBFC;
    cursor: pointer;
}
.btnCsv:hover{
    background-color: #034EAA;
}
.btnCsv:active {
    background-color: #00458D;
}

.selectedTab {
    background-color: #044BD9;
    color: #FAFBFC;
}
.selectedTab:hover {
    background-color: #034EAA;
}
.selectedTab:active {
    background-color: #00458D;
}

/* Campo de filtros */
.filterField {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px 5% 10px;
}
.inputText {
    width: 15%;
    height: 30px;
    padding: 0;
    margin: 0;
    border-radius: 10px;
    font-size: 15px;
    text-indent: 10px;
}
.inputTextDark {
    background-color: #45525B;
    color: #FAFBFC;
    border: 1px solid #3F484F;
}
.inputTextLight {
    background-color: #F0F2F5;
    color: #2A2D30;
    border: 1px solid #CCCCCC;
}
.inputText:focus {
    border: 1px solid #044BD9;
    outline: none;
}
.btnFilter {
    width: 15%;
    height: 30px;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    background-color: #044BD9;
    color: #FAFBFC;
    cursor: pointer;
}
.btnFilter:hover {
    background-color: #034EAA;
}
.btnFilter:active {
    background-color: #00458D;
}

@media only screen and (max-width: 750px) {
    .contentPage {
        width: 100%;
    }

    .contentPageOpen {
        margin-left: 60px;
    }

    .tabOption {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0 20px;
    }

    .tabActivity {
        width: 100%;
    }

    .tabBox {
        width: 100%;
        padding: 0 20px;
    }

    .filterField{
        flex-direction: column;

    }

    .dateFilter, .selectHeader, .selectOption, .btnFilter, .inputText {
        width: 80% !important;
        margin-bottom: 20px;
    }

  }
.dateFilter {
    position: relative;
    top: -15px;
    width: 15%;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
  }
  .dateFilter span, .dateFilter span {
    margin-left: 10px;
    font-size: 10px;
    margin-bottom: 2px;
  }
  .dateInput {
    width: calc(100% - 5px);
    height: 28px;
    text-indent: 5px;
    font-family: 'Roboto', sans-serif;
    padding-right: 5px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: #FAFBFC;
  }
  .dateInput:focus {
    border:1px solid #044BD9;
  }
  .dateInputDark {
    background-color: #45525B;
    border: 1px solid #45525B;
  }
  .dateInputLight {
    background-color: #F6F7F8;
    border: 1px solid #CCCCCC;
    color: #2C3135;
  }
  .labelDark {
    color: #FAFBFC;
  }
  .labelLight {
    color: #2C3135;
  }
.selectType {
    position: relative;
    width: 15%;
    height: 30px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: all ease 0.2s;
}
.selectOpen {
    border-radius: 10px 10px 0 0;
}
.selectClose {
    border-radius: 10px;
}

/* Cabeçalho */
.selectHeader {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}
.selectHeader span, .selectOption div span {
    margin-left: 10px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selectHeaderDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
}
.selectHeaderLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
}
.selectHeaderDark span {
    color: #FAFBFC;
}
.selectHeaderLight span {
    color: #2C3135;
}
.selectArrow {
    margin-left: auto;
    margin-right: 10px;
}
.selectArrowOpen {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/* Opções */
.selectOption {
    position: absolute;
    width: 100%;
    border-radius: 0 0 10px 10px;
    top: 30px;
}
.selectOptionDark {
    background-color: #45525B;
}
.selectOptionLight {
    background-color: #F0F2F5;
}
.selectOptionHidden {
    display: none;
}
.selectOption div {
    width: calc(100% - 7px);
    padding-left: 7px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-top: 1px solid #3F484F;
}
.selectOptionDark div {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}
.selectOptionLight div {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3130;
}
.selectOptionDark div:hover {
    background-color: #2C3130;
}
.selectOptionLight div:hover {
    background-color: #CCCCCC;
}
.selectOption div:first-child {
    border-top: 1px solid #044BD9;
}
.selectOption div:last-child {
    border-radius: 0 0 10px 10px;
}

@media only screen and (max-width: 750px) {
    .selectType {
        width: 100%;
        margin-bottom: 20px
    }
}
.selectAgent {
    position: relative;
    width: 15%;
    height: 30px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: all ease 0.2s;
}
.selectOpen {
    border-radius: 10px 10px 0 0;
}
.selectClose {
    border-radius: 10px;
}

/* Cabeçalho */
.selectHeader {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}
.selectHeader span, .selectOption div span {
    margin-left: 10px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selectHeaderDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
}
.selectHeaderLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
}
.selectHeaderDark span {
    color: #FAFBFC;
}
.selectHeaderLight span {
    color: #2C3135;
}
.selectArrow {
    margin-left: auto;
    margin-right: 10px;
}
.selectArrowOpen {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/* Opções */
.selectOption {
    position: absolute;
    width: 100%;
    border-radius: 0 0 10px 10px;
    top: 30px;
    z-index: 99999999999;
}
.selectOptionDark {
    background-color: #45525B;
}
.selectOptionLight {
    background-color: #F0F2F5;
}
.selectOptionHidden {
    display: none;
}
.selectOption div {
    width: calc(100% - 7px);
    padding-left: 7px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-top: 1px solid #3F484F;
}
.selectOptionDark div {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}
.selectOptionLight div {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3130;
}
.selectOptionDark div:hover {
    background-color: #2C3130;
}
.selectOptionLight div:hover {
    background-color: #CCCCCC;
}
.selectOption div:first-child {
    border-top: 1px solid #044BD9;
}
.selectOption div:last-child {
    border-radius: 0 0 10px 10px;
}

@media only screen and (max-width: 750px) {
    .selectAgent {
        width: 100%;
        margin-bottom: 20px;
    }
}
.tableActivity {
    width: 90%;
    margin: 0 5%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}
.tableActivity thead, .tableActivity tr {
    width: inherit;
}
.tableActivity th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}
.authorWorkchat, .userWorkchat, .creationWorkchat {
    width: 20%;
}
.messageWorkchat {
    width: 35%;
}
.viewWorkchat {
    width: 5%;
}
.tableActivity td {
    height: 30px;
    max-height: 30px;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableTdDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}
.tableTdLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}
.tableActivity td span {
    margin-left: 10px;
}
.iconTable {
    width: 20px;
    margin: auto;
    display: flex;
    cursor: pointer;
}
.workchatModal {
    position: absolute;
    height: calc(100% - 90px);
    margin: 90px 0 0 2px;
}
.workchatMenuOpen {
    width: calc(100% - 302px);
}
.workchatMenuClose {
    width: calc(100% - 62px);
}
.workchatBoxModal {
    width: 80%;
    margin: auto;
    margin-top: 2%;
    border-radius: 10px;
    padding: 20px;
}
.workchatBoxModalDark {
    background-color: #2C3135;
    color: #FAFBFC;
    border: 1px solid #3F484F;
}
.workchatBoxModalLight {
    background-color: #FAFBFC;
    color: #2C3135;
    border: 1px solid #CCCCCC;
}
.workchatTitleBox {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5%;
}
.titleDetails {
    font-size: 18px;
    font-weight: bold;
}
.closeIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.contentModal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.infoBox {
    width: 28%;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    margin-top: 2%;
    padding: 2%;
    max-height: 50px;
    overflow-y: scroll;
}
.infoBoxDark {
    background-color: #3F484F;
}
.infoBoxLight {
    background-color: #F0F2F5;
}
.infoBox span:first-child {
    font-weight: bold;
}
.infoBoxScroll {
    max-height: 50px;
    overflow-y: scroll;
}
.tableActivity {
    width: 90%;
    margin: 0 5%;
    border-spacing: 0px;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}
.tableActivity thead, .tableActivity tr {
    width: inherit;
}
.tableActivity th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}
.creationComments, .idComments, .userComments, .linkComments {
    width: 15%;
}
.commentComments {
    width: 35%;
}
.viewComments {
    width: 5%;
}
.tableActivity td {
    height: 30px;
    max-height: 30px;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableTdDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}
.tableTdLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}
.tableActivity td span {
    margin-left: 10px;
}
.iconTable {
    width: 15px;
    margin: auto;
    display: flex;
    cursor: pointer;
}
.tableActivity {
    width: 90%;
    margin: 0 5%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}
.tableActivity thead, .tableActivity tr {
    width: inherit;
}
.tableActivity th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}
.creationPosts, .idPosts, .groupPosts, .userPosts {
    width: 15%;
}
.contentPosts {
    width: 35%;
}
.viewPosts {
    width: 5%;
}
.tableActivity td {
    height: 30px;
    max-height: 30px;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableTdDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}
.tableTdLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}
.tableActivity td span {
    margin-left: 10px;
}
.iconTable {
    width: 20px;
    margin: auto;
    display: flex;
    cursor: pointer;
}
.tableActivity {
    width: 90%;
    margin: 0 5%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}
.tableActivity thead, .tableActivity tr {
    width: inherit;
}
.tableActivity th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}
.authorFeed, .idFeed, .creationFeed {
    width: 20%;
}
.messageFeed {
    width: 35%;
}
.viewFeed {
    width: 5%;
}
.tableActivity td {
    height: 30px;
    max-height: 30px;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableTdDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}
.tableTdLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}
.tableActivity td span {
    margin-left: 10px;
}
.iconTable {
    width: 20px;
    margin: auto;
    display: flex;
    cursor: pointer;
}
.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5% 1.5%;
}
.pagination span {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #FAFBFC;
}
.pagination button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #FAFBFC;
}
.arrowPage {
    width: 15px;
    height: 15px;
    margin-top: 1px;
}
.previousArrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-right: 2px;
}
.nextArrow {
    margin-left: 3px;
}
/* Estrutura */
.chat {
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.backgroundDark {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  z-index: -1;
}
.backgroundLight {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #F2F2F2;
  z-index: -1;
}

.contentPage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: all ease 0.2s;
}
.contentPageOpen {
  margin-left: 300px;
}
.contentPageClose {
  margin-left: 60px;
}
.contentPageDark {
  background-color: #262626;
}
.contentPageLight {
  background-color: #F2F2F2;
}

/* Inputs de texto */
.companyBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatName {
  margin: 2% 0 0 2% ;
  width: 300px;
  height: 30px;
  border-radius: 10px;
  text-indent: 10px;
  text-decoration: none;
}
.chatNameDark {
  background-color: #45525B;
  border: 1px solid #3F484F;
  color: #FAFBFC;
}
.chatNameLight {
  background-color: #F0F2F5;
  border: 1px solid #CCCCCC;
  color: #2A2D30;
}
.chatName:focus {
  border: 1px solid #044BD9;
	text-decoration: none;
	outline: 1px solid #044BD9;
}
.chatInputBox {
  display: flex;
}
.btnInputBox {
  margin: 2% 0 0 2% ;
  width: 300px;
  height: 34px;
  border-radius: 10px;
  background-color: #044BD9;
  border: none;
  outline: none;
  text-indent: 10px;
  color: #FAFBFC;
  cursor: pointer;
}
/* Caixa de mensagens */
.displayNames {
  display: flex;
  justify-content: space-between;
  margin: 2% 2% 1% 2%;
  padding: 0 10px;
}
.displayNamesDark {
  color: #FAFBFC;
}
.displayNamesLight {
  color: #2A2D30;
}
.chatBox {
  width: 94%;
  height: 60%;
  margin: 0 2% 2% 2%;
  padding: 1%;
  border-radius: 10px;
  overflow: auto;
}

.chatBoxDark {
  background-color: #18191a;
}
.chatBoxLight {
  background-color: #dddddd;

}
.chatMessage {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.senderSide {
  display: flex;
  margin: 10px 0;
  position: relative;
}
.senderSide img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  margin-right: 10px;
}
.receiverSide {
  display: flex;
  margin: 10px 0;
  position: relative;
}
.receiverSide img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  margin-left: 10px;
}
.userTooltipDark {
  background-color: #45525B;
  border: 1px solid #3F484F;
  color: #FAFBFC;
}
.userTooltipLight {
  background-color: #F0F2F5;
  border: 1px solid #CCCCCC;
  color: #2A2D30;
}
.senderText {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  padding: 0 10px;
  /* height: 30px;*/
  min-height: 30px;
  margin-bottom: 5px;
  height: auto;
  border-radius: 10px 10px 10px 1px;
  display: flex;
  align-items: center;
}
.senderTextDark {
  background-color: #45525B;
  color: #FAFBFC;
}
.senderTextLight {
  background-color: #cccccc;
  color: #2A2D30;
}
.receiverText {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  padding: 0 10px;
  /* height: 30px; */
  min-height: 30px;
  margin-bottom: 5px;
  border-radius: 10px 10px 1px 10px;
  display: flex;
  align-items: center;
}
.receiverTextDark {
  background-color: #3e4042;
  color: #FAFBFC;
}
.receiverTextLight {
  background-color: #cccccc;
  color: #2A2D30;
}

@media only screen and (max-width: 750px) {

  .contentPage {
    width: 100%;
    display: flex;
    align-items: center !important;
  }

  .contentPageOpen {
      margin-left: 60px;
  }

  .companyBox {
    width: 80%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .btnCsv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .chatInputBox {
    flex-direction: column;
  }
}

/* Estrutura */
.auditors {
    width: 100%;
    display: flex;
    font-family: 'Roboto', sans-serif;
}

.backgroundDark {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #262626;
    z-index: -1;
}

.backgroundLight {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #F2F2F2;
    z-index: -1;
}

.contentPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all ease 0.2s;
}

.contentPageOpen {
    margin-left: 300px;
}

.contentPageClose {
    margin-left: 60px;
}

.contentPageDark {
    background-color: #262626;
}

.contentPageLight {
    background-color: #F2F2F2;
}

/* Estrutura do conteúdo */
.auditorsBox {
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .contentPageOpen {
        margin-left: 60px;
        width: 100%;
    }
    
    .auditorsBox {
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .formAuditors {
        width: 80%;
    }

    .tableAuditors {
        width: 80%;
    }

}
/* Estrutura da tabela */
.tableAuditors {
    width: 40%;
    min-width: 600px;
    height: -webkit-min-content;
    height: min-content;
    margin: 5% 2%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}

.tableAuditors thead,
.tableAuditors tr {
    width: inherit;
}

.tableAuditors th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}

.tableAuditors td {
    height: 30px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableAuditorsDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}

.tableAuditorsLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}

.tableAuditors td span {
    margin-left: 10px;
}

/* Head da tabela */
.activeAuditors {
    width: 85%;
}

.iconAuditors {
    min-width: 80px;
}

/* Body da tabela */
.iconTable {
    width: 15px;
    margin: auto;
    display: flex;
    cursor: pointer;
}

.iconBoxAuditors {
    display: flex;
}

/* Popup de editar e deletar */
.popUpEdit,
.popUpDelete {
    position: absolute;
    right: 60px;
    margin-top: 5px;
    min-width: 250px;
    width: 20%;
    height: 60px;
    border-radius: 10px;
}

.popUpDark {
    background-color: #2C3135;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight {
    background-color: #FAFBFC;
    border: 1px solid #CCCCCC;
}

.popUpLight span {
    color: #2C3135;
}

.popUpEdit {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.popUpEdit input {
    height: 30px;
    outline: none;
    border-radius: 10px;
    padding: 0 10px;
}

.popUpDark input {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight input {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3135;
}

.popUpEdit input:focus {
    border: 1px solid #044BD9;
}

.iconSave {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.popUpDelete {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #FAFBFC;
}

.popUpDelete div {
    display: flex;
    width: 70%;
    justify-content: space-around;
}

.popUpDelete div div {
    width: 70px;
    height: 25px;
    background-color: #044BD9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease 0.2s;
}

.popUpDelete div div:hover {
    background-color: #034EAA;
}

.popUpDelete div div:active {
    background-color: #00458D;
}

.selectPermission {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .tableAuditors {
        width: 80%;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
}
.selectPermission {
    position: relative;
    width: 80%;
    height: 30px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: all ease 0.2s;
}
.selectOpen {
    border-radius: 10px 10px 0 0;
}
.selectClose {
    border-radius: 10px;
}

/* Cabeçalho */
.selectHeader {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}
.selectHeader span, .selectOption div span {
    margin-left: 10px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selectHeaderDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
}
.selectHeaderLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
}
.selectHeaderDark span {
    color: #FAFBFC;
}
.selectHeaderLight span {
    color: #2C3135;
}
.selectArrow {
    margin-left: auto;
    margin-right: 10px;
}
.selectArrowOpen {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/* Opções */
.selectOption {
    position: absolute;
    width: 100%;
    border-radius: 0 0 10px 10px;
    top: 30px;
}
.selectOptionDark {
    background-color: #45525B;
}
.selectOptionLight {
    background-color: #F0F2F5;
}
.selectOptionHidden {
    display: none;
}
.selectOption div {
    width: calc(100% - 7px);
    padding-left: 7px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-top: 1px solid #3F484F;
}
.selectOptionDark div {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}
.selectOptionLight div {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3130;
}
.selectOptionDark div:hover {
    background-color: #2C3130;
}
.selectOptionLight div:hover {
    background-color: #CCCCCC;
}
.selectOption div:first-child {
    border-top: 1px solid #044BD9;
}
.selectOption div:last-child {
    border-radius: 0 0 10px 10px;
}
.formAuditors {
    width: 50%;
    margin: 2%;
}

.textColorDark {
    color: #FAFBFC;
}

.textColorLight {
    color: #2C3135;
}

/* Título */
.createAuditors {
    font-size: 18px;
    font-weight: bold;
    margin: 20px;
}

/* Campos de input */
.auditorsData {
    display: flex;
    flex-direction: column;
}

.emailAuditors,
.phoneAuditors {
    width: 300px;
    height: 30px;
    margin: 10px 20px;
    text-indent: 10px;
    border-radius: 10px;
    text-decoration: none;
}

.inputTextDark {
    background-color: #45525B;
    color: #FAFBFC;
    border: 1px solid #3F484F;
}

.inputTextLight {
    background-color: #F0F2F5;
    color: #2A2D30;
    border: 1px solid #CCCCCC;
}

.emailAuditors:focus,
.phoneAuditors:focus {
    border: 1px solid #034EAA;
    outline: 1px solid #034EAA;
}

/* Permissões */
.auditorsPermission {
    display: flex;
    margin: 20px;
}

.permissionList {
    margin-right: 20px;
}

.permissionList span {
    font-size: 15px;
    margin: 5px;
    font-weight: bold;
}

.optionPermission {
    margin: 10px 0;
}

.optionPermission label {
    margin-left: 5px;
    font-size: 15px;
}

/* Botão de submit */
.submitAuditors {
    width: 250px;
    height: 30px;
    border-radius: 10px;
    margin: 20px;
    background-color: #034EAA;
    border: none;
    outline: none;
    color: #FAFBFC;
    transition: all ease 0.2s;
    cursor: pointer;
}

.submitAuditors:hover {
    background-color: #034EAA;
}

@media only screen and (max-width: 1000px) {
    .formAuditors {
        width: 80%;
    }
}
/* Estrutura */
.alerts {
    display: flex;
    font-family: 'Roboto', sans-serif;
}

.backgroundDark {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #262626;
    z-index: -1;
}

.backgroundLight {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #F2F2F2;
    z-index: -1;
}

.contentPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all ease 0.2s;
}

.contentPageOpen {
    margin-left: 301px;
}

.contentPageClose {
    margin-left: 61px;
}

.contentPageDark {
    background-color: #262626;
}

.contentPageLight {
    background-color: #F2F2F2;
}

/* Conteúdo da página */
.boxContent {
    display: flex;
}

.firstContent {
    width: 50%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titlePage {
    font-size: 16px;
    margin: 5% 0px 5% 5%;
    align-self: normal;
}

.titlePageDark {
    color: #FAFBFC;
}

.titlePageLight {
    color: #2C3135;
}

.wordsBox {
    width: 95%;
    min-height: 25px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 40%;
    padding: 5px;
    border-radius: 10px;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.backgroundBoxDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
}

.backgroundBoxLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
}

.wordsBoxItem {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
}

.wordsBoxItemDark {
    background-color: #3F484F;
    border: 1px solid #2C3135;
    color: #FAFBFC;
}

.wordsBoxItemLight {
    color: #2C3135;
    border: 1px solid #CCCCCC;
    background-color: #F0F2F5;
}

.iconClose {
    width: 8px;
    margin-left: 5px;
    margin-top: 2px;
    cursor: pointer;
}

.inputWords {
    margin: 5% 0 5% 5%;
    width: 95.5%;
    height: 30px;
    border-radius: 10px;
    text-indent: 10px;
    text-decoration: none;
}

.inputWordsDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.inputWordsLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2A2D30;
}

.inputWords:focus {
    border: 1px solid #044BD9;
    text-decoration: none;
    outline: 1px solid #044BD9;
}

.firstContent button, .secondContent button {
    width: 300px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background-color: #044BD9;
    color: #FAFBFC;
    margin: 0 0 0 4%;
    font-size: 15px;
    cursor: pointer;
    transition: all ease 0.2s;
}

.firstContent button:hover, .secondContent button:hover {
    background-color: #034EAA;
}

.firstContent button:active, .secondContent button:active {
    background-color: #00458D;
}

.secondContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputUser {
    margin: 5% 0 5% 5%;
    width: 90%;
    height: 30px;
    border-radius: 10px;
    text-indent: 10px;
    text-decoration: none;
}

.inputUserDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.inputUserLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2A2D30;
}

.inputUser:focus {
    border: 1px solid #044BD9;
    text-decoration: none;
    outline: 1px solid #044BD9;
}

@media only screen and (max-width: 750px) {
    .contentPageOpen {
        margin-left: 60px;
    }

    .inputWords {
        width: 95%;
        transition: all ease 0.2s;
    }

    .firstContent button, .secondContent button {
        width: 150px;
        transition: all ease 0.2s;
    }

    .inputUser {
        width: 95%;
        transition: all ease 0.2s;
    }

    .boxContent {
        width: 100%;
        height: 100%;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    .firstContent {
        width: 80%;
    }

    .secondContent {
        width: 80%;
    }

    .tableAlerts {
        width: 100%;
    }
}
/* Estrutura da tabela */
.tableAlerts {
    width: 90%;
    /* min-width: 600px; */
    height: -webkit-min-content;
    height: min-content;
    margin: 5% 5%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}

.tableAlerts thead, .tableAlerts tr {
    width: inherit;
}

.tableAlerts th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}

.tableAlerts td {
    height: 30px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableAlertsDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}

.tableAlertsLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}

.tableAlerts td span {
    margin-left: 10px;
}

/* Head da tabela */
.activeAlerts {
    width: 85%;
}

.iconAlerts {
    min-width: 80px;
}

/* Body da tabela */
.iconTable {
    width: 15px;
    margin: auto;
    display: flex;
    cursor: pointer;
}

.iconBoxAlerts {
    display: flex;
}

/* Popup de editar e deletar */
.popUpDelete {
    position: absolute;
    right: 60px;
    margin-top: 5px;
    min-width: 250px;
    width: 20%;
    height: 60px;
    border-radius: 10px;
}

.popUpDark {
    background-color: #2C3135;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight {
    background-color: #FAFBFC;
    border: 1px solid #CCCCCC;
}

.popUpLight span {
    color: #2C3135;
}

.popUpDark input {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight input {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3135;
}

.popUpDelete {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #FAFBFC;
}

.popUpDelete div {
    display: flex;
    width: 70%;
    justify-content: space-around;
}

.popUpDelete div div {
    width: 70px;
    height: 25px;
    background-color: #044BD9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease 0.2s;
}

.popUpDelete div div:hover {
    background-color: #034EAA;
}

.popUpDelete div div:active {
    background-color: #00458D;
}
.editProfile {
    width: 100vw;
    height: calc(100vh - 90px);
    font-size: 16px;
    display: flex;
    font-family: 'Roboto', sans-serif;
}
.backgroundDark {
    position: fixed;
    height: inherit;
    width: inherit;
    background-color: #262626;
}

.backgroundLight {
    position: fixed;
    height: inherit;
    width: inherit;
    background-color: #F2F2F2;
}

.editProfileContent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.inputName {
    width: 400px;
	height: 30px;
	border-radius: 10px;
	border: 1px solid #CCCCCC;
	margin: 10px 0 30px;
	text-indent: 10px;
	background-color: #F0F2F5;
}
.inputName:focus {
	border: 1px solid #044BD9;
	text-decoration: none;
	outline: 1px solid #044BD9;
}
.dropImage {
    width: 300px;
    height: 30px;
    
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropImageDark {
    background-color: #3F484F;
}
.dropImageLight {
    background-color: #ccc;
}
.userProfile {
    height: 250px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.displayNone {
    display: none;
}
.editProfileContent button {
	width: 405px;
	height: 35px;
	border-radius: 10px;
	border: none;
	background-color: #044BD9;
	color: #FAFBFC;
	margin: 10px 0;
	font-size: 15px;
	cursor: pointer;
	transition: all ease 0.2s;
}
.editProfileContent button:hover {
	background-color: #034EAA;
	border: none;
	outline: none;
}
.editProfileContent button:active {
	background-color: #00458D;
	border: none;
	outline: none;
}

.editProfilePageDark {
    color: #FAFBFC;
}

.editProfilePageLight {
    color: #2C3135;
}

@media only screen and (max-width: 750px) {
    .contentPageOpen {
        margin-left: 60px;
    }   
  }
