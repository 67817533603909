/* Estrutura */
.activity {
    width: 100%;
    display: flex;
    font-family: 'Roboto', sans-serif;
}

.backgroundDark {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #262626;
    z-index: -1;
}
.backgroundLight {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #F2F2F2;
    z-index: -1;
}

.contentPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all ease 0.2s;
}
.contentPageOpen {
    margin-left: 300px;
}
.contentPageClose {
    margin-left: 60px;
}
.contentPageDark {
    background-color: #262626;
}
.contentPageLight {
    background-color: #F2F2F2;
}

/* Abas e download do CSV */
.companyBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tabActivity {
    display: flex;
    width: 96%;
    height: 35px;
    margin: 2%;
}
.tabActivityDark {
    border-bottom: 1px solid #45525B;
}
.tabActivityLight {
    border-bottom: 1px solid #CCCCCC;
}
.tabBox {
    display: flex;
    width: 50%;
}
.tabBox div:first-child {
    border-radius: 10px 0 0 0;
}
.tabBox div:last-child {
    border-radius: 0 10px 0 0;
}
.tabOption {
    width: 25%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tabOptionDark {
    background-color: #45525B;
    color: #FAFBFC;
}
.tabOptionLight {
    background-color: #F0F2F5;
    color: #2A2D30;
}
.tabOptionDark:hover {
    background-color: #3F484F;
}
.tabOptionLight:hover {
    background-color: #CCCCCC;
}
.tabBtn {
    width: 50%;
}
.btnCsv {
    width: 15%;
    height: 30px;
    margin-left: auto;
    margin: 2% 2% 0;
    border-radius: 10px;
    border: 1px solid #044BD9;
    background-color: #044BD9;
    color: #FAFBFC;
    cursor: pointer;
}
.btnCsv:hover{
    background-color: #034EAA;
}
.btnCsv:active {
    background-color: #00458D;
}

.selectedTab {
    background-color: #044BD9;
    color: #FAFBFC;
}
.selectedTab:hover {
    background-color: #034EAA;
}
.selectedTab:active {
    background-color: #00458D;
}

/* Campo de filtros */
.filterField {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px 5% 10px;
}
.inputText {
    width: 15%;
    height: 30px;
    padding: 0;
    margin: 0;
    border-radius: 10px;
    font-size: 15px;
    text-indent: 10px;
}
.inputTextDark {
    background-color: #45525B;
    color: #FAFBFC;
    border: 1px solid #3F484F;
}
.inputTextLight {
    background-color: #F0F2F5;
    color: #2A2D30;
    border: 1px solid #CCCCCC;
}
.inputText:focus {
    border: 1px solid #044BD9;
    outline: none;
}
.btnFilter {
    width: 15%;
    height: 30px;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    background-color: #044BD9;
    color: #FAFBFC;
    cursor: pointer;
}
.btnFilter:hover {
    background-color: #034EAA;
}
.btnFilter:active {
    background-color: #00458D;
}

@media only screen and (max-width: 750px) {
    .contentPage {
        width: 100%;
    }

    .contentPageOpen {
        margin-left: 60px;
    }

    .tabOption {
        width: fit-content;
        padding: 0 20px;
    }

    .tabActivity {
        width: 100%;
    }

    .tabBox {
        width: 100%;
        padding: 0 20px;
    }

    .filterField{
        flex-direction: column;

    }

    .dateFilter, .selectHeader, .selectOption, .btnFilter, .inputText {
        width: 80% !important;
        margin-bottom: 20px;
    }

  }