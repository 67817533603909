.workchatModal {
    position: absolute;
    height: calc(100% - 90px);
    margin: 90px 0 0 2px;
}
.workchatMenuOpen {
    width: calc(100% - 302px);
}
.workchatMenuClose {
    width: calc(100% - 62px);
}
.workchatBoxModal {
    width: 80%;
    margin: auto;
    margin-top: 2%;
    border-radius: 10px;
    padding: 20px;
}
.workchatBoxModalDark {
    background-color: #2C3135;
    color: #FAFBFC;
    border: 1px solid #3F484F;
}
.workchatBoxModalLight {
    background-color: #FAFBFC;
    color: #2C3135;
    border: 1px solid #CCCCCC;
}
.workchatTitleBox {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5%;
}
.titleDetails {
    font-size: 18px;
    font-weight: bold;
}
.closeIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.contentModal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.infoBox {
    width: 28%;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    margin-top: 2%;
    padding: 2%;
    max-height: 50px;
    overflow-y: scroll;
}
.infoBoxDark {
    background-color: #3F484F;
}
.infoBoxLight {
    background-color: #F0F2F5;
}
.infoBox span:first-child {
    font-weight: bold;
}
.infoBoxScroll {
    max-height: 50px;
    overflow-y: scroll;
}