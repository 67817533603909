.tableActivity {
    width: 90%;
    margin: 0 5%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}
.tableActivity thead, .tableActivity tr {
    width: inherit;
}
.tableActivity th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}
.authorFeed, .idFeed, .creationFeed {
    width: 20%;
}
.messageFeed {
    width: 35%;
}
.viewFeed {
    width: 5%;
}
.tableActivity td {
    height: 30px;
    max-height: 30px;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableTdDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}
.tableTdLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}
.tableActivity td span {
    margin-left: 10px;
}
.iconTable {
    width: 20px;
    margin: auto;
    display: flex;
    cursor: pointer;
}
.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5% 1.5%;
}
.pagination span {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #FAFBFC;
}
.pagination button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #FAFBFC;
}
.arrowPage {
    width: 15px;
    height: 15px;
    margin-top: 1px;
}
.previousArrow {
    transform: rotate(180deg);
    margin-right: 2px;
}
.nextArrow {
    margin-left: 3px;
}