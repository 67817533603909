/* Estrutura da tabela */
.tableAlerts {
    width: 90%;
    /* min-width: 600px; */
    height: min-content;
    margin: 5% 5%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}

.tableAlerts thead, .tableAlerts tr {
    width: inherit;
}

.tableAlerts th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}

.tableAlerts td {
    height: 30px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableAlertsDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}

.tableAlertsLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}

.tableAlerts td span {
    margin-left: 10px;
}

/* Head da tabela */
.activeAlerts {
    width: 85%;
}

.iconAlerts {
    min-width: 80px;
}

/* Body da tabela */
.iconTable {
    width: 15px;
    margin: auto;
    display: flex;
    cursor: pointer;
}

.iconBoxAlerts {
    display: flex;
}

/* Popup de editar e deletar */
.popUpDelete {
    position: absolute;
    right: 60px;
    margin-top: 5px;
    min-width: 250px;
    width: 20%;
    height: 60px;
    border-radius: 10px;
}

.popUpDark {
    background-color: #2C3135;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight {
    background-color: #FAFBFC;
    border: 1px solid #CCCCCC;
}

.popUpLight span {
    color: #2C3135;
}

.popUpDark input {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight input {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3135;
}

.popUpDelete {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #FAFBFC;
}

.popUpDelete div {
    display: flex;
    width: 70%;
    justify-content: space-around;
}

.popUpDelete div div {
    width: 70px;
    height: 25px;
    background-color: #044BD9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease 0.2s;
}

.popUpDelete div div:hover {
    background-color: #034EAA;
}

.popUpDelete div div:active {
    background-color: #00458D;
}