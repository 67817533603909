/* Estrutura */
.chat {
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.backgroundDark {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  z-index: -1;
}
.backgroundLight {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #F2F2F2;
  z-index: -1;
}

.contentPage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: all ease 0.2s;
}
.contentPageOpen {
  margin-left: 300px;
}
.contentPageClose {
  margin-left: 60px;
}
.contentPageDark {
  background-color: #262626;
}
.contentPageLight {
  background-color: #F2F2F2;
}

/* Inputs de texto */
.companyBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatName {
  margin: 2% 0 0 2% ;
  width: 300px;
  height: 30px;
  border-radius: 10px;
  text-indent: 10px;
  text-decoration: none;
}
.chatNameDark {
  background-color: #45525B;
  border: 1px solid #3F484F;
  color: #FAFBFC;
}
.chatNameLight {
  background-color: #F0F2F5;
  border: 1px solid #CCCCCC;
  color: #2A2D30;
}
.chatName:focus {
  border: 1px solid #044BD9;
	text-decoration: none;
	outline: 1px solid #044BD9;
}
.chatInputBox {
  display: flex;
}
.btnInputBox {
  margin: 2% 0 0 2% ;
  width: 300px;
  height: 34px;
  border-radius: 10px;
  background-color: #044BD9;
  border: none;
  outline: none;
  text-indent: 10px;
  color: #FAFBFC;
  cursor: pointer;
}
/* Caixa de mensagens */
.displayNames {
  display: flex;
  justify-content: space-between;
  margin: 2% 2% 1% 2%;
  padding: 0 10px;
}
.displayNamesDark {
  color: #FAFBFC;
}
.displayNamesLight {
  color: #2A2D30;
}
.chatBox {
  width: 94%;
  height: 60%;
  margin: 0 2% 2% 2%;
  padding: 1%;
  border-radius: 10px;
  overflow: auto;
}

.chatBoxDark {
  background-color: #18191a;
}
.chatBoxLight {
  background-color: #dddddd;

}
.chatMessage {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.senderSide {
  display: flex;
  margin: 10px 0;
  position: relative;
}
.senderSide img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  margin-right: 10px;
}
.receiverSide {
  display: flex;
  margin: 10px 0;
  position: relative;
}
.receiverSide img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  margin-left: 10px;
}
.userTooltipDark {
  background-color: #45525B;
  border: 1px solid #3F484F;
  color: #FAFBFC;
}
.userTooltipLight {
  background-color: #F0F2F5;
  border: 1px solid #CCCCCC;
  color: #2A2D30;
}
.senderText {
  width: fit-content;
  margin-right: auto;
  padding: 0 10px;
  /* height: 30px;*/
  min-height: 30px;
  margin-bottom: 5px;
  height: auto;
  border-radius: 10px 10px 10px 1px;
  display: flex;
  align-items: center;
}
.senderTextDark {
  background-color: #45525B;
  color: #FAFBFC;
}
.senderTextLight {
  background-color: #cccccc;
  color: #2A2D30;
}
.receiverText {
  width: fit-content;
  margin-left: auto;
  padding: 0 10px;
  /* height: 30px; */
  min-height: 30px;
  margin-bottom: 5px;
  border-radius: 10px 10px 1px 10px;
  display: flex;
  align-items: center;
}
.receiverTextDark {
  background-color: #3e4042;
  color: #FAFBFC;
}
.receiverTextLight {
  background-color: #cccccc;
  color: #2A2D30;
}

@media only screen and (max-width: 750px) {

  .contentPage {
    width: 100%;
    display: flex;
    align-items: center !important;
  }

  .contentPageOpen {
      margin-left: 60px;
  }

  .companyBox {
    width: 80%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .btnCsv {
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .chatInputBox {
    flex-direction: column;
  }
}
