.welcome {
  font-size: 48px;
  font-weight: bold;
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.backgroundDark {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  z-index: -1;
}
.backgroundLight {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #F2F2F2;
  z-index: -1;
}

.initialPageContent {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  text-align: center;
  margin: auto;
}
.initialPageDark {
  background-color: #262626;
  color: #FAFBFC;
}
.initialPageLight {
  color: #2C3135;
}
.welcomeText {
  font-size: 24px;
}
.linkGeral {
  color: #0073EA;
  text-decoration: none;
}

@media only screen and (max-width: 750px) {
  .contentPageOpen {
      margin-left: 60px;
  }   
}