.tableActivity {
    width: 90%;
    margin: 0 5%;
    border-spacing: 0px;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}
.tableActivity thead, .tableActivity tr {
    width: inherit;
}
.tableActivity th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}
.creationComments, .idComments, .userComments, .linkComments {
    width: 15%;
}
.commentComments {
    width: 35%;
}
.viewComments {
    width: 5%;
}
.tableActivity td {
    height: 30px;
    max-height: 30px;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableTdDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}
.tableTdLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}
.tableActivity td span {
    margin-left: 10px;
}
.iconTable {
    width: 15px;
    margin: auto;
    display: flex;
    cursor: pointer;
}