.title {
  min-height: 90px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-family: 'Roboto', sans-serif;
}
.titleOpen {
  margin-left: 300px;
}
.titleClose {
  margin-left: 60px;
}
.titleDark {
  background-color: #262626;
  color: #FAFBFC;
  border-bottom: 1px solid #3F484F;
  transition: all ease 0.2s;
}
.titleLight {
  background-color: #F2F2F2;
  color: #2C3135;
  border-bottom: 1px solid #CCCCCC;
  transition: all ease 0.2s;
}
.productTitle {
  font-size: 30px;
  font-weight: bold;
}
.pageTitle {
  font-size: 25px;
  margin: 5px 0 0 5px;
}

@media only screen and (max-width: 750px) {
  .title {
    width: 100%;
  }   
}