/* Estrutura */
.alerts {
    display: flex;
    font-family: 'Roboto', sans-serif;
}

.backgroundDark {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #262626;
    z-index: -1;
}

.backgroundLight {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #F2F2F2;
    z-index: -1;
}

.contentPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all ease 0.2s;
}

.contentPageOpen {
    margin-left: 301px;
}

.contentPageClose {
    margin-left: 61px;
}

.contentPageDark {
    background-color: #262626;
}

.contentPageLight {
    background-color: #F2F2F2;
}

/* Conteúdo da página */
.boxContent {
    display: flex;
}

.firstContent {
    width: 50%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titlePage {
    font-size: 16px;
    margin: 5% 0px 5% 5%;
    align-self: normal;
}

.titlePageDark {
    color: #FAFBFC;
}

.titlePageLight {
    color: #2C3135;
}

.wordsBox {
    width: 95%;
    min-height: 25px;
    height: fit-content;
    max-height: 40%;
    padding: 5px;
    border-radius: 10px;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.backgroundBoxDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
}

.backgroundBoxLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
}

.wordsBoxItem {
    width: fit-content;
    height: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
}

.wordsBoxItemDark {
    background-color: #3F484F;
    border: 1px solid #2C3135;
    color: #FAFBFC;
}

.wordsBoxItemLight {
    color: #2C3135;
    border: 1px solid #CCCCCC;
    background-color: #F0F2F5;
}

.iconClose {
    width: 8px;
    margin-left: 5px;
    margin-top: 2px;
    cursor: pointer;
}

.inputWords {
    margin: 5% 0 5% 5%;
    width: 95.5%;
    height: 30px;
    border-radius: 10px;
    text-indent: 10px;
    text-decoration: none;
}

.inputWordsDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.inputWordsLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2A2D30;
}

.inputWords:focus {
    border: 1px solid #044BD9;
    text-decoration: none;
    outline: 1px solid #044BD9;
}

.firstContent button, .secondContent button {
    width: 300px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background-color: #044BD9;
    color: #FAFBFC;
    margin: 0 0 0 4%;
    font-size: 15px;
    cursor: pointer;
    transition: all ease 0.2s;
}

.firstContent button:hover, .secondContent button:hover {
    background-color: #034EAA;
}

.firstContent button:active, .secondContent button:active {
    background-color: #00458D;
}

.secondContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputUser {
    margin: 5% 0 5% 5%;
    width: 90%;
    height: 30px;
    border-radius: 10px;
    text-indent: 10px;
    text-decoration: none;
}

.inputUserDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.inputUserLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2A2D30;
}

.inputUser:focus {
    border: 1px solid #044BD9;
    text-decoration: none;
    outline: 1px solid #044BD9;
}

@media only screen and (max-width: 750px) {
    .contentPageOpen {
        margin-left: 60px;
    }

    .inputWords {
        width: 95%;
        transition: all ease 0.2s;
    }

    .firstContent button, .secondContent button {
        width: 150px;
        transition: all ease 0.2s;
    }

    .inputUser {
        width: 95%;
        transition: all ease 0.2s;
    }

    .boxContent {
        width: 100%;
        height: 100%;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    .firstContent {
        width: 80%;
    }

    .secondContent {
        width: 80%;
    }

    .tableAlerts {
        width: 100%;
    }
}