.formAuditors {
    width: 50%;
    margin: 2%;
}

.textColorDark {
    color: #FAFBFC;
}

.textColorLight {
    color: #2C3135;
}

/* Título */
.createAuditors {
    font-size: 18px;
    font-weight: bold;
    margin: 20px;
}

/* Campos de input */
.auditorsData {
    display: flex;
    flex-direction: column;
}

.emailAuditors,
.phoneAuditors {
    width: 300px;
    height: 30px;
    margin: 10px 20px;
    text-indent: 10px;
    border-radius: 10px;
    text-decoration: none;
}

.inputTextDark {
    background-color: #45525B;
    color: #FAFBFC;
    border: 1px solid #3F484F;
}

.inputTextLight {
    background-color: #F0F2F5;
    color: #2A2D30;
    border: 1px solid #CCCCCC;
}

.emailAuditors:focus,
.phoneAuditors:focus {
    border: 1px solid #034EAA;
    outline: 1px solid #034EAA;
}

/* Permissões */
.auditorsPermission {
    display: flex;
    margin: 20px;
}

.permissionList {
    margin-right: 20px;
}

.permissionList span {
    font-size: 15px;
    margin: 5px;
    font-weight: bold;
}

.optionPermission {
    margin: 10px 0;
}

.optionPermission label {
    margin-left: 5px;
    font-size: 15px;
}

/* Botão de submit */
.submitAuditors {
    width: 250px;
    height: 30px;
    border-radius: 10px;
    margin: 20px;
    background-color: #034EAA;
    border: none;
    outline: none;
    color: #FAFBFC;
    transition: all ease 0.2s;
    cursor: pointer;
}

.submitAuditors:hover {
    background-color: #034EAA;
}

@media only screen and (max-width: 1000px) {
    .formAuditors {
        width: 80%;
    }
}