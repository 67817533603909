.loaderComponentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  position: fixed;
  width: calc(100vw - 62px);
  height: 100vh;
  opacity: 70%;
}

.loaderThemeDark {
  background-color: #262626;
}

.loaderThemeLight {
  background-color: #F2F2F2;
}

.lds-dual-ring-dark {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring-dark:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #F2F2F2;
  border-color: #F2F2F2 transparent #F2F2F2 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #262626;
  border-color: #262626 transparent #262626 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}