/* Estrutura da tabela */
.tableAuditors {
    width: 40%;
    min-width: 600px;
    height: min-content;
    margin: 5% 2%;
    border-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}

.tableAuditors thead,
.tableAuditors tr {
    width: inherit;
}

.tableAuditors th {
    height: 30px;
    background-color: #044BD9;
    color: #FAFBFC;
}

.tableAuditors td {
    height: 30px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableAuditorsDark td {
    background-color: #2C3135;
    border: 0.5px solid #3F484F;
    color: #FAFBFC;
}

.tableAuditorsLight td {
    background-color: #F0F2F5;
    border: 0.5px solid #CCCCCC;
    color: #2C3135;
}

.tableAuditors td span {
    margin-left: 10px;
}

/* Head da tabela */
.activeAuditors {
    width: 85%;
}

.iconAuditors {
    min-width: 80px;
}

/* Body da tabela */
.iconTable {
    width: 15px;
    margin: auto;
    display: flex;
    cursor: pointer;
}

.iconBoxAuditors {
    display: flex;
}

/* Popup de editar e deletar */
.popUpEdit,
.popUpDelete {
    position: absolute;
    right: 60px;
    margin-top: 5px;
    min-width: 250px;
    width: 20%;
    height: 60px;
    border-radius: 10px;
}

.popUpDark {
    background-color: #2C3135;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight {
    background-color: #FAFBFC;
    border: 1px solid #CCCCCC;
}

.popUpLight span {
    color: #2C3135;
}

.popUpEdit {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.popUpEdit input {
    height: 30px;
    outline: none;
    border-radius: 10px;
    padding: 0 10px;
}

.popUpDark input {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}

.popUpLight input {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3135;
}

.popUpEdit input:focus {
    border: 1px solid #044BD9;
}

.iconSave {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.popUpDelete {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #FAFBFC;
}

.popUpDelete div {
    display: flex;
    width: 70%;
    justify-content: space-around;
}

.popUpDelete div div {
    width: 70px;
    height: 25px;
    background-color: #044BD9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease 0.2s;
}

.popUpDelete div div:hover {
    background-color: #034EAA;
}

.popUpDelete div div:active {
    background-color: #00458D;
}

.selectPermission {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .tableAuditors {
        width: 80%;
        min-width: fit-content;
    }
}