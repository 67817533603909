.userMenu {
    position: absolute;
    bottom: 5px;
    width: 250px;
    height: fit-content;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    transition: all ease 0.2s;
}
.userMenuOpen {
    left: 305px;
    transition: all ease 0.2s;
}
.userMenuClose {
    left: 65px;
    transition: all ease 0.2s;
}
.userMenuDark {
    border: 1px solid #3F484F;
    background-color: #2C3135;
    color: #FAFBFC;
}
.userMenuLight {
    border: 1px solid #CCCCCC;
    background-color: #F6F7F8;
    color: #2C3135;
}
.userOptionDark {
    display: flex;
    width: 240px;
    height: 40px;
    border-radius: 10px;
    margin: 7px 5px;
    align-items: center;
    cursor: pointer;
}
.userOptionLight {
    display: flex;
    width: 240px;
    height: 40px;
    border-radius: 10px;
    margin: 7px 5px;
    align-items: center;
    cursor: pointer;
}
.userOptionDark:hover {
    background-color: #45525B;
}
.userOptionLight:hover {
    background-color: #DDDEDF;
}
.userOptionDark span, .userOptionLight span {
    margin: 13px 12.5px 12.5px 0;
}
.iconUser {
    width: 20px;
    height: 20px;
    margin: 10px;
}

.toggleTheme {
    margin-left: auto;
    margin-right: 10px;
}