.dateFilter {
    position: relative;
    top: -15px;
    width: 15%;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
  }
  .dateFilter span, .dateFilter span {
    margin-left: 10px;
    font-size: 10px;
    margin-bottom: 2px;
  }
  .dateInput {
    width: calc(100% - 5px);
    height: 28px;
    text-indent: 5px;
    font-family: 'Roboto', sans-serif;
    padding-right: 5px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: #FAFBFC;
  }
  .dateInput:focus {
    border:1px solid #044BD9;
  }
  .dateInputDark {
    background-color: #45525B;
    border: 1px solid #45525B;
  }
  .dateInputLight {
    background-color: #F6F7F8;
    border: 1px solid #CCCCCC;
    color: #2C3135;
  }
  .labelDark {
    color: #FAFBFC;
  }
  .labelLight {
    color: #2C3135;
  }