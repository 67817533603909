.toggle {
    position: relative;
    width: 50px;
    height: 20px;
    border-radius: 10px;
}
.toggleDark {
    background-color: #044BD9;
    border: 1px solid #044BD9;
    transition: all ease 0.2s;
}
.toggleLight {
    background-color: #FAFBFC;
    border: 1px solid #CCCCCC;
    transition: all ease 0.2s;
}
.circleToggle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 1px;
    transition: all ease 0.2s;
}
.circleToggleOn {
    position: absolute;
    left: 30px;
}
.circleToggleOff {
    position: absolute;
    left: 0;
}
.circleToggleDark {
    background-color: #FAFBFC;
    transition: all ease 0.2s;
}
.circleToggleLight {
    background-color: #818386;
    transition: all ease 0.2s;
}