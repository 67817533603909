.login {
	display: flex;
	flex-direction: column;
	height: 100vh;
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #FAFBFC;
}

.logoAlest {
	height: 60px;
	width: 100vw;
}

.logoAlest img {
	width: 80px;
	margin: 10px;
}

.logoEdiscovery {
	height: 100px;
	width: 65%;
	margin: 20px 17.5%;
	border-bottom: 1px solid #CCCCCC;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logoEdiscovery img {
	width: 250px;
}

.loginField {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 50px;
}

.loginField span {
	font-size: 24px;
	font-weight: bold;
	color: #3F484F;
	margin-bottom: 10px;
}

.loginField input {
	width: 400px;
	height: 30px;
	border-radius: 10px;
	border: 1px solid #CCCCCC;
	margin: 10px 0;
	text-indent: 10px;
	background-color: #F0F2F5;
	color: #3F484F;
}

.loginField input:focus {
	border: 1px solid #044BD9;
	text-decoration: none;
	outline: 1px solid #044BD9;
}

.loginField button {
	width: 405px;
	height: 35px;
	border-radius: 10px;
	border: none;
	background-color: #044BD9;
	color: #FAFBFC;
	margin: 10px 0;
	font-size: 15px;
	cursor: pointer;
	transition: all ease 0.2s;
}

.loginField button:hover {
	background-color: #034EAA;
	border: none;
	outline: none;
}

.LoginField button :active {
	background-color: #00458D;
}

.productDescription {
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 30px;
}

.productDescription span {
	width: 450px;
	font-style: italic;
	color: #7D838C;
}

.boxPassword {
	position: relative;
	display: flex;
	align-items: center;
}

.passwordShow {
	position: absolute;
	right: 10px;
	cursor: pointer;
}

@media only screen and (max-width: 750px) {
	.logoAlest img {
		width: 60px;
		margin: 10px;
		transition: all ease 0.2s;
	}

	.logoEdiscovery img {
		width: 200px;
		transition: all ease 0.2s;
	}

	.loginField span {
		font-size: 20px;
		transition: all ease 0.2s;
	}

	.loginField input {
		width: 300px;
		transition: all ease 0.2s;
	}

	.loginField button {
		width: 305px;
		transition: all ease 0.2s;
	}

	.productDescription {
		font-size: 25px;
		transition: all ease 0.2s;
	}
}