.mainMenu {
    position: fixed;
    height: calc(100vh - 16px);
    display: flex;
    flex-direction: column;
    transition: all ease 0.2s;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    padding: 7px 0;
    z-index: 999999999;
}
.mainMenuOpen {
    width: 300px;
}
.mainMenuClose {
    width: 60px;
}
.mainMenuDark {
    background-color: #2C3135; 
    border: 1px solid #3F484F;
    color: #FAFBFC
}
.mainMenuLight {
    background-color: #F6F7F8; 
    border: 1px solid #CCCCCC;
    color: #2C3135;
}

/* Ícone de abrir e fechar o menu */
.toggleMenu {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
    z-index: 9999999;
}
.toggleMenuOpen {
    top: 37.5px;
    left: 293.5px;
}
.toggleMenuClose {
    top: 37.5px;
    left: 53.5px;
}
.toggleMenuDark {
    background-color: #FAFBFC;
    border: 1px solid #3F484F;
}
.toggleMenuLight {
    background-color: #2C3135;
    border: 1px solid #CCCCCC;
}
.arrowMenuOpen {
    width: 7px;
    height: 18px;
    margin: 0.6px 0 0 -3px;
    transform: rotate(180deg);
}
.arrowMenuClose {
    width: 7px;
    height: 18px;
    margin-left: 3px;
}

/* Opções do Menu */
.link {
    text-decoration: none;
}
.optionMenu {
    padding-top: 10px;
}
.option {
    height: 45px;
    margin: 7.5px;
    cursor: pointer;
    font-weight: normal;
}
.optionDark {
    color: #FAFBFC;
}
.optionLight {
    color: #2C3135;
}
.optionOpen {
    width: 275px;
    display: flex;
    align-items: center;
}
.optionClose {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
}
.option span {
    margin-left: 15px;
}

.optionDark:hover {
    background-color: #45525B;
    border-radius: 10px;
    cursor: pointer;
}
.optionLight:hover {
    background-color: #DDDEDF;
    border-radius: 10px;
    cursor: pointer;
}
.optionHighlight {
    background-color: #83A1EA !important;
    color: #0073EA !important;
    border-radius: 10px;
    transition: all ease 0.2s;
}
.iconMenu {
    width: 20px;
    height: 20px;
    transition: all ease 0.2s;
}
.iconMenuOpen {
    margin-left: 20px;
}

/* Acesso ao menu de usuário */
.userInfo {
    transition: all ease 0.2s;
    cursor: pointer;
}
.userInfo span {
    margin-left: 15px;
    font-weight: normal;
}
.userInfoDark {
    color: #FAFBFC;
}
.userInfoLight {
    color: #2C3135;
}
.userInfoClose {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 20px;
}
.userInfoOpen {
    display: flex;
    align-items: center;
    margin: auto 0 20px 20px;
}
.userInfo img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    cursor: pointer;
}