.editProfile {
    width: 100vw;
    height: calc(100vh - 90px);
    font-size: 16px;
    display: flex;
    font-family: 'Roboto', sans-serif;
}
.backgroundDark {
    position: fixed;
    height: inherit;
    width: inherit;
    background-color: #262626;
}

.backgroundLight {
    position: fixed;
    height: inherit;
    width: inherit;
    background-color: #F2F2F2;
}

.editProfileContent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.inputName {
    width: 400px;
	height: 30px;
	border-radius: 10px;
	border: 1px solid #CCCCCC;
	margin: 10px 0 30px;
	text-indent: 10px;
	background-color: #F0F2F5;
}
.inputName:focus {
	border: 1px solid #044BD9;
	text-decoration: none;
	outline: 1px solid #044BD9;
}
.dropImage {
    width: 300px;
    height: 30px;
    
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropImageDark {
    background-color: #3F484F;
}
.dropImageLight {
    background-color: #ccc;
}
.userProfile {
    height: 250px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.displayNone {
    display: none;
}
.editProfileContent button {
	width: 405px;
	height: 35px;
	border-radius: 10px;
	border: none;
	background-color: #044BD9;
	color: #FAFBFC;
	margin: 10px 0;
	font-size: 15px;
	cursor: pointer;
	transition: all ease 0.2s;
}
.editProfileContent button:hover {
	background-color: #034EAA;
	border: none;
	outline: none;
}
.editProfileContent button:active {
	background-color: #00458D;
	border: none;
	outline: none;
}

.editProfilePageDark {
    color: #FAFBFC;
}

.editProfilePageLight {
    color: #2C3135;
}

@media only screen and (max-width: 750px) {
    .contentPageOpen {
        margin-left: 60px;
    }   
  }