.selectAgent {
    position: relative;
    width: 15%;
    height: 30px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: all ease 0.2s;
}
.selectOpen {
    border-radius: 10px 10px 0 0;
}
.selectClose {
    border-radius: 10px;
}

/* Cabeçalho */
.selectHeader {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}
.selectHeader span, .selectOption div span {
    margin-left: 10px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selectHeaderDark {
    background-color: #45525B;
    border: 1px solid #3F484F;
}
.selectHeaderLight {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
}
.selectHeaderDark span {
    color: #FAFBFC;
}
.selectHeaderLight span {
    color: #2C3135;
}
.selectArrow {
    margin-left: auto;
    margin-right: 10px;
}
.selectArrowOpen {
    transform: rotate(180deg);
}

/* Opções */
.selectOption {
    position: absolute;
    width: 100%;
    border-radius: 0 0 10px 10px;
    top: 30px;
    z-index: 99999999999;
}
.selectOptionDark {
    background-color: #45525B;
}
.selectOptionLight {
    background-color: #F0F2F5;
}
.selectOptionHidden {
    display: none;
}
.selectOption div {
    width: calc(100% - 7px);
    padding-left: 7px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-top: 1px solid #3F484F;
}
.selectOptionDark div {
    background-color: #45525B;
    border: 1px solid #3F484F;
    color: #FAFBFC;
}
.selectOptionLight div {
    background-color: #F0F2F5;
    border: 1px solid #CCCCCC;
    color: #2C3130;
}
.selectOptionDark div:hover {
    background-color: #2C3130;
}
.selectOptionLight div:hover {
    background-color: #CCCCCC;
}
.selectOption div:first-child {
    border-top: 1px solid #044BD9;
}
.selectOption div:last-child {
    border-radius: 0 0 10px 10px;
}

@media only screen and (max-width: 750px) {
    .selectAgent {
        width: 100%;
        margin-bottom: 20px;
    }
}