/* Estrutura*/
.dashboard {
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.backgroundDark {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  z-index: -1;
}
.backgroundLight {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #F2F2F2;
  z-index: -1;
}

.contentPage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: all ease 0.2s;
}
.contentPageOpen {
  margin-left: 300px;
}
.contentPageClose {
  margin-left: 60px;
}
.contentPageDark {
  background-color: #262626;
}
.contentPageLight {
  background-color: #F2F2F2;
}

/* Botão CSV */
.btnCSV {
  width: 250px;
  height: 35px;
  font-size: 15px;
  border-radius: 10px;
  margin: 2%;
  padding: 0px;
  background-color: #044BD9;
  color: #FAFBFC;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnCSV span {
  margin-left: 10px;
}
.iconDownload {
  width: 15px;
  margin-right: 10px;
}

/* Conteúdo dashboard */
.analysisDataBox {
  display: flex;
  margin-top: 5%;
}

/* Dados de análise */
.analysisData {
  width: 30%;
  margin: 0 2.5%;
}
.titleDataBox {
  font-weight: bold;
  width: 100%;
  border-bottom: 1px solid #044BD9;
  height: 45px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.titleDataBox span {
  margin-left: 15px;
}
.dataBox {
  display: flex;
  display: flex;
  align-items: center;
  height: 45px;
}
.textDark {
  color: #FAFBFC;
}
.textLight {
  color: #2C3135;
}
.dataName {
  margin-left: 15px;
}
.dataValue {
  margin-left: auto;
  margin-right: 15px;
}

/* Gráfico */
.chartDashboard {
  width: 60%;
  height: 300px;
  margin: 0 2.5%;
}

@media only screen and (max-width: 750px) {

  .backgroundDark, .backgroundLight {
    height: 100%;
  }

  .contentPageOpen {
      margin-left: 60px;
      height: fit-content;
  }
  
    .analysisDataBox {
      display: flex;
      flex-direction: column;
      justify-content: center; 
      align-items: center; 
    }

  .analysisData, .chartDashboard {
    width: 90%;
    padding: 20px 0;
  }
}